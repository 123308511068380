<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <profile-header
      :me="me"
      :header-data="profileData.header"
    />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about
            :me="me"
            :about-data="profileData.userAbout"
          />
        </b-col>
        <!--/ about suggested page and twitter feed -->
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import useHttp from '@/http/useHttp'
import { notify } from '@/utils/utils'
import { BRow, BCol } from 'bootstrap-vue'

import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,

    ProfileHeader,
    ProfileAbout,
  },
  data() {
    return {
      me: {
        ext: {},
        user: {},
      },
      profileData: { },
    }
  },
  created() {
    this.$http.get('/profile/data').then(res => { this.profileData = res.data })
    this.loadme()
  },
  methods: {
    loadme() {
      useHttp.getMe().then(res => {
        this.me = res.data.data
      }).catch(error => {
        notify('Error', error.toString(), 'danger')
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
